body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.text-muted {
  line-height: 1.2;
}

.btn {
  margin: 0.2em;
}

/* starburst */

.starburst {
	display:block;
	width:2.5em;
	height:2.5em;
	background:#fe0;
	-webkit-transform:rotate(-45deg);
	-moz-transform:rotate(-45deg);
	rotation:-45deg;
	position:relative;
	text-align:center;
	text-decoration:none;
	color:#000;
	font-weight:bold;
	font-family:Arial, sans-serif;
  float: right;
}

.starburst span {
	display:block;
	width:2.5em;
	height:2.5em;
	background:#fe0;
	-webkit-transform:rotate(22.5deg);
	-moz-transform:rotate(22.5deg);
	rotation:22.5deg;
}
